import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PageLayout } from "../../components/layouts"
import PageWrapper from "../../components/pageWrapper"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"
import ArticleItem from "./ArticleItem"
import "./index.scss"

const ArticlePage = props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges
  return (
    <PageLayout background="#f5f7f9" showBlog={false}>
      <SEO title="Long Term Care Articles | Corey Rieck | LTCPG" />
      <SEO ogDescription="These long term care articles can help you learn more about the complex long term care options. Corey Rieck and his team focus on your LTC education." />

      <PageHeader
        header="Long Term Care Articles"
        subheader="Learn more about how long term care can impact your family and finances though these informative articles."
      />
      <div className="article-items">
        {posts.map(({ node }) => (
          <ArticleItem
            date={new Date(node.frontmatter.date).toLocaleDateString()}
            slug={node.fields.slug}
            title={node.frontmatter.title}
            titletag={node.frontmatter.titletag}
            image={node.frontmatter.previewImage}
            description={node.frontmatter.description}
            posttype={node.frontmatter.posttype}
          />
        ))}
      </div>
    </PageLayout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { posttype: { eq: "article" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            description
            date
            title
            titletag
            previewImage
            posttype
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
